import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, concatMap, finalize, map, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@core/services/user.service';
import { Observable, of } from 'rxjs';
import { RsLoaderService } from '@lib/rs-ngx';
import { WorkOrdersService } from '@shared/services/leasing-company/work-orders/work-orders.service';
import {
  LockWarningDialogComponent
} from '@app/leasing-company/work-orders/shared/lock-warning-dialog/lock-warning-dialog.component';
import { WorkOrderLockInfo } from '@shared/models/work-orders/work-order-lock-info';

export function lockWorkorderGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot) => {
    const workOrderService = inject(WorkOrdersService);
    const userService = inject(UserService);
    const dialog = inject(MatDialog);
    const router = inject(Router);
    const spinner = inject(RsLoaderService);

    spinner.show({ id: 'lock-work-order' });
    return workOrderService.getWorkOrderLockInfo(route.params['id'] as string).pipe(
      finalize(() => spinner.hide({ id: 'lock-work-order' })),
      concatMap(workOrderLockInfo =>
        (workOrderLockInfo.lockingUserId > 0 && workOrderLockInfo.lockingUserId !== userService.getUserId()) ?
          openLockDialog(workOrderLockInfo, dialog) :
          of({ workOrderId: workOrderLockInfo.workOrderId, force: false })),
      switchMap(({
        workOrderId, force
      }) => {
        if (workOrderId) {
          spinner.show({ id: 'lock-work-order' });
          return workOrderService.lockWorkOrder(workOrderId, force).pipe(
            finalize(() => spinner.hide({ id: 'lock-work-order' })),
            map(() => true),
            catchError(() => of(false)),
          );
        } else {
          return of(router.parseUrl('/leasing-company/work-orders'));
        }
      }),
    );
  };
}

function openLockDialog(
    workOrderLockInfo: WorkOrderLockInfo,
    dialog: MatDialog,
): Observable<{ workOrderId: string | null, force: boolean }> {
  const dialogRef = dialog.open(LockWarningDialogComponent, {
    width: '580px',
    autoFocus: false,
    restoreFocus: false,
    data: {
      userName: workOrderLockInfo.lockingUserName
    }
  });
  return dialogRef.afterClosed().pipe(
    map(dialogResult => (dialogResult && dialogResult.result) ?
      { workOrderId: workOrderLockInfo.workOrderId, force: true } :
      { workOrderId: null, force: false })
  );
}

