import { createFeatureSelector, createSelector } from '@ngrx/store';
import { workOrderOverviewFeatureKey, WorkOrderOverviewState } from './work-order-overview.reducer';
import { SelectWorkOrderOverviewTableSettings } from '../models/selector-work-order-overview-table-settings';
import { omit } from 'lodash';
import { WorkOrderParameters } from '@shared/models/work-orders/work-order-parameters';

const selectWorkOrderOverview = createFeatureSelector<WorkOrderOverviewState>(
  workOrderOverviewFeatureKey
);

export const selectWorkOrdersFilters = createSelector(
  selectWorkOrderOverview,
  (state) => state.tableSettings.tableFilters
);

export const selectWorkOrdersSearchTerm = createSelector(
  selectWorkOrderOverview,
  (state) => state.tableSettings.tableFilters.searchTerm
);

export const selectWorkOrdersTableSettings = createSelector(
  selectWorkOrderOverview,
  (state) => state.tableSettings
);

export const selectWorkOrdersParams = createSelector(
  selectWorkOrdersTableSettings,
  (state) => {
    const workOrderStates: string[] = Object.keys(state.tableFilters.state).filter(key => state.tableFilters.state[key]).flatMap(value => {
      switch (value) {
        case 'TO_APPROVE':
          return ['NOT_AUTOMATICALLY_APPROVED', 'IN_TREATMENT_BY_LEASING_COMPANY', 'SENT'];
        case 'AT_SUPPLIER':
          return ['APPROVED', 'NOT_APPROVED', 'IN_TREATMENT_BY_SUPPLIER'];
        default:
          return [];
      }
    });

    const params: WorkOrderParameters = {
      ...omit(state.tableOptions, '_sortBy'),
      _sortBy: mapSortBy(state.tableOptions._sortBy),
      ...omit(state.tableFilters, 'state', 'leasingContractGroup'),
      state: workOrderStates,
      finishedState: getFinishedState(!!state.tableFilters.state?.COMPLETED, workOrderStates),
      leasingContractGroupId: state.tableFilters.leasingContractGroup?.id,
      creationDateFrom: state.tableFilters.creationDateFrom?.format('YYYY-MM-DD'),
      creationDateTo: state.tableFilters.creationDateTo?.format('YYYY-MM-DD'),
    };

    return params;
  }
);

export const selectWorkOrdersForOverview = createSelector(
  selectWorkOrderOverview,
  (state) => state.workOrders
);

export const selectTotalPagesForOverview = createSelector(
  selectWorkOrderOverview,
  (state) => state.totalPages
);

export const selectWorkOrderNumberOfFilters = createSelector(
  selectWorkOrderOverview,
  (state) => state.numberOfFilters
);

export const selectWorkOrderOverviewTableSettings = createSelector(
  selectWorkOrderOverview,
  (state) => {
    const settings = state.tableSettings.tableOptions;
    return <SelectWorkOrderOverviewTableSettings>{
      page: settings._page,
      resultsPerPage: settings._pageSize,
      sortDirection: settings._sortDirection,
      sortBy: settings._sortBy,
      totalPages: state.totalPages
    };
  }
);

function getFinishedState(completedStateSelected: boolean, workOrderStates: string[]): 'ALL' | 'FINISHED' | 'NOT_FINISHED' {
  if (workOrderStates.length > 0 && !completedStateSelected) {
    return 'NOT_FINISHED';
  } else if (workOrderStates.length === 0 && completedStateSelected) {
    return 'FINISHED';
  } else {
    return 'ALL';
  }
}

function mapSortBy(sortByColumn: string): string {
  switch (sortByColumn) {
    case 'RECEIVED':
      return 'SEND_DATE';
    case 'NUMBER':
      return 'WORK_ORDER_NUMBER';
    case 'SUPPLIER':
      return 'SUPPLIER_NAME';
    case 'APPROVED':
      return 'APPROVED_AMOUNT';
    case 'LAST_TREATED':
      return 'LAST_HANDLED_BY_USER';
    case 'STATUS':
      return 'STATE';
    case 'LEASING_CONTRACT_GROUP':
      return 'LEASING_CONTRACT_GROUP_CODE';
    case 'LEASING_COMPANY':
      return 'LEASING_COMPANY_NAME';
    default:
      return sortByColumn;
  }
}
