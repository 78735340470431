<h1 mat-dialog-title>
  {{'ALREADY_IN_USE' | translate}}
  <button
    mat-dialog-close
    mat-icon-button
    [mat-dialog-close]="{ result: false, data }"
  >
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  {{'WORK_ORDER_IN_USE_BY' | translate }} <strong>{{ data.userName }}</strong>
  <br />
  {{'ARE_YOU_SURE_YOU_WANT_TO_CLAIM_IT' | translate }}
</div>

<div mat-dialog-actions>
  <button
    (click)="onNoClick()"
    mat-button
    data-testid="lock-workorder-warning-dialog-cancel"
  >{{"CANCEL" | translate}}</button>
  <button
    (click)="onYesClick()"
    cdkFocusInitial
    class='rs-btn--primary'
    mat-button
    data-testid="lock-workorder-warning-dialog-confirm"
  >{{ 'OK' | translate}}</button>
</div>
<!-- Are you sure you want to delete this linked activity? -->