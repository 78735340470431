import { ActivatedRouteSnapshot, CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { DetailsComponent } from '@app/leasing-company/work-orders/pages/details/details.component';
import { WorkOrdersService } from '@shared/services/leasing-company/work-orders/work-orders.service';

export function unlockWorkorderGuard(): CanDeactivateFn<DetailsComponent> {
  return (_detailsComponent: DetailsComponent, route: ActivatedRouteSnapshot) => {
    const workOrderService = inject(WorkOrdersService);

    return workOrderService.deleteWorkOrderLock(route.params['id'] as string).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  };
}

