import { createAction, props } from '@ngrx/store';
import { WorkOrderSummary } from '@shared/models/work-orders/work-order-summary';
import { WorkOrderFilterFormValue } from '@shared/models/work-orders/work-order-parameters';

export const loadWorkOrderOverview = createAction(
  '[WorkOrderOverview] Load WorkOrderOverview'
);

export const loadWorkOrderOverviewSuccess = createAction(
  '[WorkOrderOverview] Load WorkOrderOverview Success',
  props<{ results: WorkOrderSummary[], totalPages: number }>()
);

export const updateWorkOrderFilters = createAction(
  '[Filter] Update Work Order Filters',
  props<{ filters: WorkOrderFilterFormValue }>()
);

export const updateWorkOrderNumberOfFilters = createAction(
  '[Filter] Update Work Order Number Of Filters'
);

export const updateWorkOrderPage = createAction(
  '[Filter] Update Work Order page',
  props<{ page: number; resultsPerPage: number }>()
);

export const updateWorkOrderSort = createAction(
  '[Filter] Update Work Order sort',
  props<{ sortDirection: string; sortBy: string }>()
);

export const updateWorkOrderSearchTerm = createAction(
  '[Filter] Update search term',
  props<{ searchTerm: string }>()
);
