import { Actions, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { CURRENT_USER_DESTROYED, CURRENT_USER_INITIALIZED } from '../../core/states/core.actions';
import { exhaustMap, takeUntil } from 'rxjs/operators';
import { Directive } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class AbstractOnRunEffects implements OnRunEffects {

  protected constructor(
    protected readonly actions$: Actions
  ) {
  }

  public ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    return this.actions$.pipe(
      ofType(CURRENT_USER_INITIALIZED),
      exhaustMap(() => {
        return resolvedEffects$.pipe(
          takeUntil(
            this.actions$.pipe(ofType(CURRENT_USER_DESTROYED))
          )
        );
      })
    );
  }
}
