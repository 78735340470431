import { createReducer, on } from '@ngrx/store';
import {
  loadWorkOrderOverviewSuccess,
  updateWorkOrderFilters,
  updateWorkOrderNumberOfFilters,
  updateWorkOrderPage,
  updateWorkOrderSearchTerm,
  updateWorkOrderSort
} from './work-order-overview.actions';
import { rsCountFilters } from '@lib/utils/count-filters/count-filters';
import { produce } from 'immer';
import { WorkOrderFilterFormValue } from '@shared/models/work-orders/work-order-parameters';
import { WorkOrderSummary } from '@shared/models/work-orders/work-order-summary';

export const workOrderOverviewFeatureKey = 'workOrderOverview';

export interface WorkOrderOverviewState {
  workOrders: WorkOrderSummary[];
  tableSettings: {
    tableOptions: {
      _page: number,
      _pageSize: number,
      _sortBy: string,
      _sortDirection: string,
    };
    tableFilters: WorkOrderFilterFormValue;
  },
  numberOfFilters: number;
  totalPages: number;
}

export const initialState: WorkOrderOverviewState = {
  workOrders: [],
  tableSettings: {
    tableOptions: {
      _page: 1,
      _pageSize: 15,
      _sortBy: 'SEND_DATE',
      _sortDirection: 'ASC',
    },
    tableFilters: {
      searchTerm: '',
      state: {
        TO_APPROVE: true,
        AT_SUPPLIER: false,
        COMPLETED: false
      },
      onlyWithinCompetence: true
    },
  },
  numberOfFilters: 0,
  totalPages: 0,

};

export const workOrderOverviewReducer = createReducer(
  initialState,
  on(loadWorkOrderOverviewSuccess, (state, {
    results, totalPages
  }) =>
    produce(state, (draft) => {
      draft.workOrders = results;
      draft.totalPages = totalPages;
    })),

  on(updateWorkOrderFilters, (state, { filters }) =>
    produce(state, (draft) => {
      draft.tableSettings.tableFilters = {
        ...state.tableSettings.tableFilters,
        ...filters,
      };
      draft.tableSettings.tableOptions = {
        ...state.tableSettings.tableOptions,
        _page: 1,
      }
    })),

  on(updateWorkOrderNumberOfFilters, (state) => {
    const numberOfFilters = rsCountFilters(state.tableSettings.tableFilters, [
      'lastHandledBy',
      'supplierId',
      'febiacBrand',
      'state',
      'workOrderNumber',
      'vin',
      'supplierLanguage',
      'supplierReference',
      'leasingCompanyNumber',
      'leasingContractNumber',
      'leasingContractGroup',
      'creationDateFrom',
      'creationDateTo',
      'approvedAmountFrom',
      'approvedAmountTo',
      'onlyWithinCompetence',
    ]);

    return produce(state, draft => {
      draft.numberOfFilters = numberOfFilters;
    });
  }),

  on(updateWorkOrderPage, (state, {
    page, resultsPerPage
  }) => {
    const newPage = state.tableSettings.tableOptions._pageSize != resultsPerPage ? 1 : page;

    return produce(state, draft => {
      draft.tableSettings.tableOptions._page = newPage;
      draft.tableSettings.tableOptions._pageSize = resultsPerPage;
    });
  }),

  on(updateWorkOrderSort, (state, {
    sortDirection, sortBy,
  }) =>
    produce(state, draft => {
      draft.tableSettings.tableOptions._sortDirection = sortDirection;
      draft.tableSettings.tableOptions._sortBy = sortBy;
    })),

  on(updateWorkOrderSearchTerm, (state, { searchTerm }) =>
    produce(state, draft => {
      draft.tableSettings.tableFilters.searchTerm = searchTerm;
      draft.tableSettings.tableOptions._page = 1;
    }))
);

