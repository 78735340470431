import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Utils from '../../../../utils';
import { environment } from '@environments/environment';
import { rsFormatPaginatedListRequestBody } from '@lib/utils/custom-rxjs-operators/rsFormatPaginatedListRequestBody';
import { RsFormatPaginatedListRequestBody } from '@lib/rs-ngx';
import { WorkOrderSummary } from '@shared/models/work-orders/work-order-summary';
import { WorkOrderParameters } from '@shared/models/work-orders/work-order-parameters';

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersOverviewService {

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
  }

  public readonly getLastHandlingUsers = (): Observable<string[]> =>  {
    return this.http.get<string[]>(`${environment.apiUrl}/leasing-company/work-order/last-handling-users`);
  }

  public getWorkOrders(filterParams: WorkOrderParameters): Observable<RsFormatPaginatedListRequestBody<WorkOrderSummary[]>> {
    const newParams = Object.assign({ userLanguage: this.translateService.currentLang }, filterParams);
    const params = Utils.getHttpParams(newParams);
    return this.http.get<WorkOrderSummary[]>(
      environment.apiUrl + '/leasing-company/work-order',
      { observe: 'response', params }
    ).pipe(
      rsFormatPaginatedListRequestBody()
    );
  }

  public downloadAsPdf(workOrderId: string): Observable<HttpResponse<Blob>> {
    return this.http.get<HttpResponse<Blob>>(`${environment.apiUrl}/leasing-company/work-order/${workOrderId}/pdf`, {
      params: { _userLanguage: this.translateService.currentLang },
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    });
  }
}
