import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-renta-icon-text',
  templateUrl: './icon-text.component.html',
  styleUrls: ['./icon-text.component.scss'],
  standalone: true,
})
export class IconTextComponent {
  @Input() public label = '';
}
