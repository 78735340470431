import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { WorkOrderLockInfo } from '@shared/models/work-orders/work-order-lock-info';

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersService {

  constructor(private readonly http: HttpClient) {
  }

  public getWorkOrderLockInfo(workOrderId: string): Observable<WorkOrderLockInfo> {
    return this.http.get<WorkOrderLockInfo>(`${environment.apiUrl}/leasing-company/work-order/${workOrderId}/lock-info`);
  }

  public lockWorkOrder(workOrderId: string, force = false): Observable<string> {
    const params = new HttpParams({ fromObject: { force: force ? 'true' : 'false' } });
    return this.http.post<string>(environment.apiUrl + `/leasing-company/work-order/${workOrderId}/lock`, null, { params });
  }

  public deleteWorkOrderLock(id: string): Observable<void> {
    return this.http.delete<void>(
      environment.apiUrl + `/leasing-company/work-order/${id}/lock`
    );
  }
}
