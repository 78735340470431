import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadWorkOrderOverview,
  loadWorkOrderOverviewSuccess,
  updateWorkOrderFilters,
  updateWorkOrderNumberOfFilters
} from './work-order-overview.actions';
import { finalize, map, switchMap } from 'rxjs/operators';
import { RsLoaderService } from '@lib/rs-ngx';
import { Store } from '@ngrx/store';
import { AbstractOnRunEffects } from '@shared/abstract-classes/abstract-lifecycle-effect';
import {
  selectWorkOrdersParams
} from '@app/leasing-company/work-orders/pages/overview/state/work-order-overview.selectors';
import { WorkOrdersOverviewService } from '@shared/services/leasing-company/work-orders/work-orders-overview.service';

@Injectable()
export class WorkOrderOverviewEffects extends AbstractOnRunEffects {

  public loadWorkOrderOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadWorkOrderOverview),
      switchMap(() => this.store.select(selectWorkOrdersParams)),
      switchMap((params) => {
        this.spinner.show({ msg: 'LOADING.LOADING_DATAS', delay: 600 });
        return this.overviewService.getWorkOrders(params).pipe(
          map((data) => loadWorkOrderOverviewSuccess({
            results: data.results,
            totalPages: data.pageCount
          })),
          finalize(() => {
            this.spinner.hide();
          })
        );
      })
    );
  });

  public updateNumberOfFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateWorkOrderFilters),
      map(() => updateWorkOrderNumberOfFilters())
    );
  });

  constructor(
    actions$: Actions,
    private readonly overviewService: WorkOrdersOverviewService,
    private readonly spinner: RsLoaderService,
    private readonly store: Store
  ) {
    super(actions$);
  }
}
